'use client';
import Script from 'next/script';

import { ReactNode } from 'react';

import classNames from 'classnames';

import { Footer } from '@shared/common/components/Footer';
import { NavLayout } from '@shared/common/components/Header/Header';
import { magicsquarePrimaryNav } from '@shared/common/components/Header/navSetting';
import { useAuth } from '@shared/common/providers/AuthProvider';
import {
  useAuthModal,
  useBanxaModal,
  useSupportModal,
} from '@shared/common/providers/GlobalModalsProvider';

import { MagicsquareLogo } from '@ui-uikit/lib/components/logos/MagicsquareLogo';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const { user, logout } = useAuth();
  const modal = useBanxaModal();
  const { userEvmWallet } = useAuth();
  const authModal = useAuthModal();
  const supportModal = useSupportModal();

  const handleConnectClick = () => {
    authModal.open();
  };

  const handleBanxaBuyToken = () => {
    modal.open({
      walletAddress: userEvmWallet?.attributes.address,
    });
  };

  return (
    <>
      <NavLayout
        primaryNavMenuTitle="Magic Square"
        Logo={MagicsquareLogo}
        user={user ? { data: user } : undefined}
        onLogin={handleConnectClick}
        onLogout={logout}
        onSupport={supportModal.open}
        onBanxaBuyToken={handleBanxaBuyToken}
        primaryNavItems={magicsquarePrimaryNav}
      >
        <div className={classNames('relative flex-1 flex flex-col')}>
          <div className="h-[14rem] bg-page-gradient absolute top-0 left-0 w-full" />

          <div className="relative">{children}</div>
        </div>

        <Footer />
      </NavLayout>

      <Script src="https://accounts.google.com/gsi/client" async defer strategy="lazyOnload" />
    </>
  );
};

export default MainLayout;
