import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

import classNames from 'classnames';

type ToastButtonSize = 'sm' | 'md' | 'lg';
type ToastButtonVariant = 'filled' | 'outlined' | 'ghost';

interface ToastButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  size?: ToastButtonSize;
  variant?: ToastButtonVariant;
  minWidth?: number;
  toastId: ToastOptions['toastId'];
  onClick: () => void;
  closeOnClick?: boolean;
}

export default function ToastButton({
  children,
  size = 'md',
  variant = 'filled',
  minWidth,
  toastId,
  onClick,
  closeOnClick = true,
  className,
  ...props
}: ToastButtonProps): JSX.Element {
  const handleButtonClick = () => {
    if (closeOnClick) {
      toast.dismiss(toastId);
    }

    onClick();
  };

  const sizeClassNamesMap: Record<ToastButtonSize, string> = {
    sm: 'text-[10px]',
    md: 'text-xs',
    lg: 'text-sm',
  };
  const variantClassNamesMap: Record<ToastButtonVariant, string> = {
    outlined: 'border border-[#005ab9]',
    ghost: 'border-none bg-none text-primary-600',
    filled: '',
  };
  const sizeClassName: string = sizeClassNamesMap[size];
  const variantClassName: string = variantClassNamesMap[variant];

  return (
    <button
      type="button"
      onClick={handleButtonClick}
      className={classNames(
        'flex items-center justify-center gap-2 rounded text-neutral-50',
        'py-2 px-4 transition whitespace-nowrap font-semibold',
        {
          'bg-[#005ab9]': variant === 'filled',
        },
        sizeClassName,
        variantClassName,
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}
