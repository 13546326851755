import { nextProjectUrl } from '@shared/constants/url';

import { NavLayoutLinkBase, PrimaryNavItem } from './types';

export const storeQuickNavItems: NavLayoutLinkBase[] = [
  {
    id: 'discover',
    label: 'Discover',
    href: nextProjectUrl.store('/'),
  },
  {
    id: 'apps',
    label: 'Apps',
    href: nextProjectUrl.store('/apps'),
  },
  {
    id: 'games',
    label: 'Games',
    href: nextProjectUrl.store('/games'),
  },
];

export const storePrimaryNav: PrimaryNavItem[] = [
  ...storeQuickNavItems,
  {
    id: 'upcoming',
    label: 'Upcoming',
    href: nextProjectUrl.store('/upcoming'),
  },
  {
    id: 'vote-and-earn',
    label: 'Vote & Earn',
    href: nextProjectUrl.store('/profile/validation-tasks'),
    isOnlyAuth: true,
  },
  // {
  //   id: 'sub-test',
  //   label: 'Sub',
  //   children: [
  //     {
  //       id: 'upcoming',
  //       label: 'Upcoming',
  //       href: nextProjectUrl.store('/upcoming'),
  //     },
  //   ]
  // }
];

export const launchpadIdoSalesNavItem: PrimaryNavItem = {
  id: 'sale',
  label: 'IDO Sales',
  href: nextProjectUrl.launchpad('/projects'),
};

export const launchpadEatsNavItem: PrimaryNavItem = {
  id: 'eats-early-access-sales',
  label: 'EATS - Early Access Sales',
  href: nextProjectUrl.launchpad('/early-sales'),
};

export const launchpadHowItWorksNavItem: PrimaryNavItem = {
  id: 'how-it-works',
  label: 'How it Works',
  href: nextProjectUrl.launchpad('/how-it-works'),
};

export const launchpadPrimaryNav: PrimaryNavItem[] = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    href: nextProjectUrl.launchpad('/portfolio'),
    isOnlyAuth: true,
  },
  launchpadIdoSalesNavItem,
  {
    id: 'road-to-ido',
    label: 'Road-to-IDO',
    href: nextProjectUrl.launchpad('/campaigns'),
  },
  launchpadEatsNavItem,
  launchpadHowItWorksNavItem,
  // {
  //   id: 'blog',
  //   label: 'Blog',
  //   href: '#'
  // }
];

export const stakingPrimaryNav: PrimaryNavItem[] = [
  {
    id: 'staking',
    label: 'Staking',
    href: nextProjectUrl.staking('/'),
  },
  {
    id: 'how-it-works',
    label: 'How It Works',
    href: 'https://docs.magicsquare.io/sqr-token/sqr-token/staking-usdsqr-tokens-with-magic-square',
    target: '_blank',
    external: true,
  },
];

export const swapPrimaryNav: PrimaryNavItem[] = [
  {
    id: 'swap',
    label: 'Swap',
    href: nextProjectUrl.swap('/'),
  },
  {
    id: 'how-it-works',
    label: 'How It Works',
    href: 'https://docs.magicsquare.io/for-users/magic-swap',
    target: '_blank',
    external: true,
  },
];

export const magicsquarePrimaryNav: PrimaryNavItem[] = [
  {
    id: 'store',
    label: 'Store',
    children: storePrimaryNav,
  },
  {
    id: 'launchpad',
    label: 'Launchpad',
    children: launchpadPrimaryNav,
  },
  {
    id: 'staking',
    label: 'Staking',
    children: stakingPrimaryNav,
  },
  {
    id: 'swap',
    label: 'Swap',
    href: nextProjectUrl.swap('/'),
  },
];
