'use client';
import dynamic from 'next/dynamic';

import React, { ReactNode } from 'react';

const AuthModal = dynamic(
  () => import('@store:web/components/common/modals/AuthModal').then((el) => el.AuthModal),
  {
    ssr: false,
  },
);

import BanxaModal from '@shared/common/components/modals/BanxaModal';
import ConnectAndSignModal from '@shared/common/components/modals/ConnectAndSignModal';
import CustomContentModal from '@shared/common/components/modals/CustomContentModal';
import SupportModal from '@shared/common/components/modals/SupportModal';

const GlobalModalsMagicsquare = ({ children }: { children: ReactNode }) => {
  return (
    <>
      {children}
      <AuthModal />
      <ConnectAndSignModal />
      <BanxaModal />
      <CustomContentModal />
      <SupportModal />
    </>
  );
};

export default GlobalModalsMagicsquare;
