'use client';
import dynamic from 'next/dynamic';

import React, { ReactNode, useMemo, useState } from 'react';

import classNames from 'classnames';

import { Wallet2 } from '@uikit/icons/Wallet2';

import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import { createWallet, type Wallet } from '@passkeys/core';
import { WalletProvider, WalletWidget } from '@passkeys/react';

const WidgetPortal = dynamic(() => import('./WidgetContainer').then((el) => el.WidgetPortal), {
  ssr: false,
});

declare global {
  interface Window {
    __passkeyWallet?: Wallet;
  }
}

const PasskeyProvider = ({ appId, children }: { appId: string; children: ReactNode }) => {
  const [show, setShow] = useState(true);
  const featureToggles = useFeatureToggles();
  const passkeyWallet = useMemo(() => {
    if (!featureToggles.passkeyAuth) {
      return;
    }

    return createWallet({
      appId,
      providers: {
        ethereum: true,
      },
    });
  }, [appId, featureToggles]);
  // const [passkeyWallet, setPasskeyWallet] = useState<Wallet | null>(null);

  // useEffect(() => {
  //   if (!featureToggles.passkeyAuth || !appId) {
  //     return;
  //   }

  //   let isMounted = true;

  //   const init = async () => {
  //     try {
  //       // NOTE: async init to prevent errors on mobile in dev, because crypto module that passkey uses will throw error
  //       // if it's not https
  //       const { createWallet } = await import('@passkeys/core');

  //       if (isMounted) {
  //         setPasskeyWallet(
  //           createWallet({
  //             appId,
  //             networks: {
  //               ethereum: true,
  //             },
  //           }),
  //         );
  //       }
  //     } catch (error) {
  //       toast.error('Unable to load passkey');
  //     }
  //   };

  //   init();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [featureToggles.passkeyAuth, appId]);

  if (!passkeyWallet) {
    return children;
  }

  return (
    <WalletProvider wallet={passkeyWallet}>
      {children}
      <WidgetPortal>
        <div
          className={classNames(
            'pointer-events-none items-center flex gap-2 passkey-widget',
            '[&>div]:transition-all ',
            '[&>div~button]:flex', // show wallet button if wallet element is present (it appears after connection)
            {
              '[&>div]:translate-x-8 [&>div]:opacity-0 [&>div]:invisible [&>div]:pointer-events-none':
                !show,
              '[&>div]:pointer-events-auto': show,
            },
          )}
        >
          <WalletWidget size="large" noAutoCompact />

          <button
            onClick={() => setShow((oldShow) => !oldShow)}
            className="relative pointer-events-auto bg-gradient-to-br from-[#F170A8] via-[#D561CE] to-[#6EBFFA] hidden rounded-full text-neutral-50 h-12 aspect-square justify-center items-center overflow-hidden"
          >
            <Wallet2 className="icon-sm" />
          </button>
        </div>
      </WidgetPortal>
    </WalletProvider>
  );
};

export default PasskeyProvider;
