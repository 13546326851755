'use client';

import React, { ReactNode } from 'react';

// import { useIsClient } from 'usehooks-ts';
import { getProcessEnvGlobalConfig } from '@shared/config/global';

import { TonConnectUIProvider } from '@tonconnect/ui-react';

const TonProvider = ({ children }: { children?: ReactNode }) => {
  // const isClient = useIsClient();
  const isDev = getProcessEnvGlobalConfig('isDev');

  // Needed for Telegram Wallet to work properly.
  // Due to the VPN, telegram are not able to get the manifest file
  const host = isDev ? 'https://magic.store' : process.env.NEXT_PUBLIC_HOST;

  // if (!isClient) {
  //   return children;
  // }

  return (
    // NOTE: throws warning: Extra attributes from the server: ontouchstart,style
    <TonConnectUIProvider language="en" manifestUrl={`${host}/tonconnect-manifest.json`}>
      {children}
    </TonConnectUIProvider>
  );
};

export default TonProvider;
