import { ComponentType } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';

import { useIsClient } from 'usehooks-ts';

import { subscribeToMagicIdNewsletter } from '@api';

import { MixpanelEventName } from '@shared/api/mixPanel';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';

import { SocialDiscord } from '@ui/uikit/components/icons/mono/SocialDiscord';
import { SocialLinkedin } from '@ui/uikit/components/icons/mono/SocialLinkedin';
import { SocialTelegram } from '@ui/uikit/components/icons/mono/SocialTelegram';
import { SocialX } from '@ui/uikit/components/icons/mono/SocialX';
import { SocialYoutube } from '@ui/uikit/components/icons/mono/SocialYoutube';
import { MagicSqaureNewLogo } from '@ui/uikit/components/logos';
import { cn } from '@ui/uikit/utils';
import { ExternalLink } from '@ui-uikit/lib/components/common/ExternalLink';
import { Tooltip } from '@ui-uikit/lib/components/common/Tooltip';
import { Karma2 } from '@ui-uikit/lib/components/icons/mono';

import NavLayoutLink from '../Header/NavLayoutLink';
import { company, contacts, developers, products } from './constants';

export const socials: {
  id: string;
  Icon: ComponentType<{ className?: string }>;
  href: string;
  name: string;
}[] = [
  {
    id: 'x',
    Icon: SocialX,
    name: 'X (aka Twitter)',
    href: 'https://x.com/MagicSquareio',
  },
  {
    id: 'discord',
    name: 'Discord',
    Icon: SocialDiscord,
    href: 'https://discord.gg/magicsquareio',
  },
  {
    id: 'telegram-official',
    name: 'Telegram Official',
    Icon: SocialTelegram,
    href: 'https://t.me/magicsquare_official',
  },
  {
    id: 'telegram-announcements',
    Icon: SocialTelegram,
    name: 'Telegram Announcements',
    href: 'https://t.me/magicsquare_announcements',
  },
  {
    id: 'youtube',
    Icon: SocialYoutube,
    name: 'Youtube',
    href: 'https://www.youtube.com/channel/UCJjHbHzZXe0T5gcn97baIbA',
  },
  {
    id: 'linkedin',
    name: 'LinkedIn',
    Icon: SocialLinkedin,
    href: 'https://www.linkedin.com/company/magicsquare',
  },
];

const Version = () => {
  const isClient = useIsClient();
  // TODO: use only one env
  const projectVersion =
    process.env.NEXT_PUBLIC_STORE_VERSION || process.env.NEXT_PUBLIC_LAUNCHPAD_VERSION;
  const showVersion = !!projectVersion && isClient && localStorage.getItem('presentTag') === '1';

  if (!showVersion) {
    return null;
  }

  return <> {projectVersion}</>;
};

export const Footer = () => {
  const isClient = useIsClient();
  const { track } = useMixPanel();
  const { user } = useAuth();

  const { mutateAsync } = useMutation({
    mutationFn: (email: string) =>
      // according to the requirements, the group is fixed to 'MAGIC_STORE' for the footer subscription
      subscribeToMagicIdNewsletter({ json: { email, group: 'MAGIC_STORE' } }),
  });

  const handleTrackedLinkClick = (eventName: MixpanelEventName) => {
    if (user?.id) {
      track(eventName, {
        distinct_id: user.id,
      });
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<{ email: string }>();

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await mutateAsync(email);

      toast.success('Subscription successful!');
      reset();
    } catch (error) {
      toast.error('Subscription unsuccessful!');
    }
  });

  // TODO: move in tailwind plugin?
  const linkClassName =
    'text-base-text-tertiary hover:text-base-text-primary transition-colors duration-150 ease-out text-caption-sm-m xl-msq:text-caption-sm-d';
  const titleClassName = 'text-caption-sm-strong-a';
  const linksGroupClassName =
    'flex flex-col flex-1 gap-8 md-msq:gap-2 md-msq:flex-row xl-msq:flex-col xl-msq:gap-8 3xl-msq:gap-4 3xl-msq:flex-row';

  return (
    <footer
      className={cn(
        'flex flex-col gap-16 xl-msq:flex-row xl-msq:gap-4',
        'pb-6 pt-24 xl-msq:pb-8 xl-msq:pt-32 v2-container',
      )}
    >
      <div className="xl-msq:max-w-[30.5rem] xl-msq:pr-[5.25rem] 3xl-msq:max-w-[28rem] 3xl-msq:pr-[3.125rem] w-full flex flex-col max-xl-msq:order-1">
        <MagicSqaureNewLogo className="w-full max-w-[13.125rem] mb-8" />

        <h5 className={cn('mb-1 gap-1 inline-flex items-center', titleClassName)}>
          Unlock
          <Karma2 className="size-3.5 inline-block xl-msq:size-4 text-base-text-orange" />
          Exclusive Perks
        </h5>

        <p className="text-caption-sm-a text-base-text-tertiary mb-3">
          Subscribe & Empower Your Future!
        </p>

        <form className="flex flex-col md-msq:flex-row gap-3 mb-10" onSubmit={onSubmit}>
          <input
            type="email"
            placeholder="Email"
            className="msq-input py-1 w-full"
            required
            {...register('email')}
          />
          {isClient && (
            <button
              type="submit"
              className={cn('msq-btn msq-btn-secondary px-[1.46875rem] msq-btn-lg', {
                'msq-btn-loading': isSubmitting,
              })}
            >
              Subscribe
            </button>
          )}
        </form>

        <div className="flex flex-wrap gap-1.5 mb-8">
          {socials.map(({ id, name, href, Icon }) => (
            <Tooltip
              key={id}
              caption={name}
              trigger={
                <ExternalLink
                  href={href}
                  className="msq-btn text-base-text-tertiary msq-btn-icon-md msq-btn-secondary msq-b"
                >
                  <Icon className="size-6" />
                </ExternalLink>
              }
            />
          ))}
        </div>

        <ul className="flex gap-4 mb-2">
          <li className="flex">
            <ExternalLink
              className={linkClassName}
              href="https://docs.magic.store/documents/legal-documents/privacy-policy"
            >
              Privacy Policy
            </ExternalLink>
          </li>
          <li className="flex">
            <ExternalLink
              className={linkClassName}
              href="https://docs.magic.store/documents/legal-documents/magic-store-terms-and-conditions"
            >
              Terms of Use
            </ExternalLink>
          </li>
        </ul>

        <p className="text-caption-xs-m xl-msq:text-caption-xs-d text-base-text-quaternary">
          © {new Date().getFullYear()} Magic Square Int Ltd. All rights reserved.
          <Version />
        </p>
      </div>

      <nav className="flex gap-2 md-msq:gap-4 flex-1">
        <div className={cn(linksGroupClassName)}>
          <div className="flex-1">
            {/*
              3xl-msq:pl-[3.125rem] on each child to make container evenly distributed to match design
              if set on parent element, it will be bigger than sibling
            */}
            <h5 className={cn(titleClassName, 'mb-6 pt-1 3xl-msq:pl-[3.125rem]')}>Products</h5>
            <ul className="flex flex-col gap-4 3xl-msq:pl-[3.125rem]">
              {products.map(({ id, href, label, mixPanelEventName }) => (
                <li className="flex" key={id}>
                  <NavLayoutLink
                    className={linkClassName}
                    target="_blank"
                    href={href}
                    onClick={() => mixPanelEventName && handleTrackedLinkClick(mixPanelEventName)}
                  >
                    {label}
                  </NavLayoutLink>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex-1">
            <h5 className={cn(titleClassName, 'mb-6 pt-1')}>For Developers</h5>
            <ul className="flex flex-col gap-4">
              {developers.map(({ id, href, label }) => (
                <li className="flex" key={id}>
                  <ExternalLink className={linkClassName} href={href}>
                    {label}
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={cn(linksGroupClassName)}>
          <div className="flex-1">
            <h5 className={cn('mb-6 pt-1', titleClassName)}>Company</h5>
            <ul className="flex flex-col gap-4">
              {company.map(({ id, href, label }) => (
                <li className="flex" key={id}>
                  <ExternalLink className={linkClassName} href={href}>
                    {label}
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1">
            <h5 className={cn(titleClassName, 'mb-6 pt-1')}>Contact Us</h5>
            <ul className="flex flex-col gap-4">
              {contacts.map(({ id, href, label }) => (
                <li className="flex" key={id}>
                  <ExternalLink className={linkClassName} href={href}>
                    {label}
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </footer>
  );
};
