'use client';

import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

interface ToastProviderProps {
  children: ReactNode;
}

const ToastProvider = ({ children }: ToastProviderProps) => {
  return (
    <>
      {children}
      <ToastContainer
        className="max-sm:p-4 flex flex-col max-sm:items-center max-sm:w-full"
        toastClassName="w-full max-w-[312px] mb-4"
      />
    </>
  );
};

export default ToastProvider;
