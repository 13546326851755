import classNames from 'classnames';

import { Modal, ModalCloseButton, ModalContent, ModalDescription, ModalHeading } from '@uikit';

import Discord from '@uikit/icons/Discord';

import { useSupportModal } from '@shared/common/providers/GlobalModalsProvider';
import { DISCORD_INVITE_URL } from '@shared/constants';

export default function SupportModal() {
  const modal = useSupportModal();

  return (
    <Modal {...modal.props}>
      <ModalContent className="p-0 w-full max-w-[420px]">
        <ModalCloseButton className="m-4" />

        <div className="px-12 pb-6">
          <ModalHeading className="mb-7">Need Support?</ModalHeading>

          <ModalDescription className="mb-12">
            For immediate help, reach out to us on our Discord Support Ticket System.
          </ModalDescription>

          <a
            target="_blank"
            href={DISCORD_INVITE_URL}
            rel="noreferrer"
            className={classNames(
              'relative shadow-header w-full h-[40px] flex items-center justify-between gap-2 text-xs font-normal text-neutral-50 leading-4 rounded-lg py-2 pl-6 pr-2 transition',
              'sm:text-sm sm:pl-14 sm:pr-3',
              'disabled:opacity-40 disabled:hover:opacity-40 disabled:active:opacity-40',
              'bg-[#4056f7] hover:bg-[#0f2bf5]',
            )}
          >
            <Discord className="icon" />
            Join Discord Support
            <Discord className="icon opacity-30" />
          </a>
        </div>
      </ModalContent>
    </Modal>
  );
}
