'use client';

import { FC, PropsWithChildren, useMemo } from 'react';
import * as DeviceDetect from 'react-device-detect';

import UserAgentParser from 'ua-parser-js';

import { createSafeContext, useSafeContext } from '@shared/helpers/context';

const UserAgentDetectContext = createSafeContext<UserAgentDetectInfo>();

type DeviceDetectFields = typeof DeviceDetect;

interface UserAgentDetectInfo extends DeviceDetectFields {
  userAgentInfo: UserAgentParser.IResult | undefined;
}

const UserAgentDetectProvider: FC<PropsWithChildren> = ({ children }) => {
  const userAgent: UserAgentDetectInfo = useMemo(() => {
    const userAgentInfo = new UserAgentParser().getResult();

    return {
      ...DeviceDetect,
      userAgentInfo,
    };
  }, []);

  return (
    <UserAgentDetectContext.Provider value={userAgent}>{children}</UserAgentDetectContext.Provider>
  );
};

export const useUserAgentDetect = () => useSafeContext(UserAgentDetectContext);

export default UserAgentDetectProvider;
