import { Modal } from '@uikit';

import { useCustomContentModal } from '@shared/common/providers/GlobalModalsProvider';
import useGlobalState from '@shared/common/providers/GlobalStateProvider';

const CustomContentModal = () => {
  const modal = useCustomContentModal();
  const { value } = useGlobalState('customContent');
  const { content } = value || {};

  return <Modal {...modal.props}>{content}</Modal>;
};

export default CustomContentModal;
