'use client'; // https://wagmi.sh/react/guides/ssr

import { ReactNode } from 'react';

import { createWeb3Modal } from '@web3modal/wagmi/react';

import { State, WagmiProvider } from 'wagmi';

import { wagmiConfig } from '@shared/common/providers/Web3Provider/wagmi';
import { WALLET_CONNECT_PROJECT_ID } from '@shared/constants';

if (!WALLET_CONNECT_PROJECT_ID) {
  throw new Error('Wallet Connect Project ID is not defined');
}

// Create modal
createWeb3Modal({
  wagmiConfig,
  projectId: WALLET_CONNECT_PROJECT_ID,
  enableAnalytics: true,
  enableOnramp: true,
});

// NOTE: QueryClientProvider must be used inside if WagmiProvider just as in the documentation
const Web3Provider = ({
  children,
  initialState,
}: {
  children: ReactNode;
  initialState?: State;
}) => {
  return (
    <WagmiProvider config={wagmiConfig} initialState={initialState}>
      {children}
    </WagmiProvider>
  );
};

export default Web3Provider;
